import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Roadmap'
}} styles={{
  'main h2': {
    padding: 10,
    backgroundColor: 'rgba(126, 252, 246, 0.2)'
  },
  'main h3': {
    padding: 10,
    backgroundColor: 'rgba(126, 252, 246, 0.1)'
  },
  'main ul': {
    paddingLeft: 0
  },
  'main ul li': {
    marginBottom: '0.5em',
    paddingLeft: 0,
    '&::marker': {
      content: '""'
    },
    'input[type="checkbox"]': {
      display: 'inline-block',
      width: 'auto',
      marginRight: 2
    }
  }
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Roadmap`}</h1>
    <blockquote>
      <p parentName="blockquote"><em parentName="p"><strong parentName="em">{`Last updated: April 16, 2021`}</strong></em></p>
    </blockquote>
    <p>{`Tasks are listed with their respective details in the `}<a parentName="p" {...{
        "href": "https://github.com/arwes/arwes/projects"
      }}>{`project boards`}</a>{`.`}</p>
    <p>{`The order in the lists does not exactly represent priority, order, neither execution.`}</p>
    <p>{`The roadmap is subject to changes according to the development progress.`}</p>
    <hr></hr>
    <h2>{`2014 - 2018`}</h2>
    <p>{`From 2014 to 2018 the project was released in alpha versions where the main
ideas have been tested out in projects. Though many features were limited, have
little support / extensibility, or buggy. The plan was to gather enough insight
to decide and build the foundation of the framework.`}</p>
    <h2>{`2019`}</h2>
    <h3>{`2019-Q1`}</h3>
    <h4>{`General`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Define and implement a new project architecture to improve organization,
simplify tooling, and facilitate developers contributions.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Research and playground new ideas and concepts to implement in the project
with the `}<a parentName="li" {...{
          "href": "https://github.com/soulextract/soulextract.com"
        }}>{`soulextract.com`}</a>{` side
project. The results are going to be used in Arwes.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Complete started animation and sounds guidelines. These guidelines will
establish the ground concepts for the framework to work.`}</li>
    </ul>
    <h3>{`2019-Q2`}</h3>
    <h4><inlineCode parentName="h4">{`@arwes/animation`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create an animation specification to define the animation system architecture.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Document React components APIs to create animation systems.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/sounds`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create a sounds specification to define the sound system architecture.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Develop the `}<inlineCode parentName="li">{`@arwes/sounds`}</inlineCode>{` package with the sounds system APIs.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Document React components APIs to create sounds systems.`}</li>
    </ul>
    <h3>{`2019-Q3`}</h3>
    <h4>{`General`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Complete started purpose guideline document. This will clarify what
the vision of the project is.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/animation`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Define animation system architecture specification.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Update animation specification with complex animation flow scenearios.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Develop the `}<inlineCode parentName="li">{`@arwes/animation`}</inlineCode>{` package with the animation system APIs.`}</li>
    </ul>
    <h3>{`2019-Q4`}</h3>
    <h4><inlineCode parentName="h4">{`@arwes/animation`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create animation management components.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create unit and group animation components.`}</li>
    </ul>
    <a name='2020' href='#2020'><h2>2020</h2></a>
    <a name='2020-Q4' href='#2020-Q4'><h3>2020-Q4</h3></a>
    <h4>{`General`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Update project dependency packages.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Define repository and website documentation file structure.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create documentation guidelines for systems, states, and events.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/64"
        }}>{`#64`}</a>{` Create Arwes UI/UX design
specification. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/61"
        }}>{`#61`}</a>{` Migrate project packages
source code to TypeScript.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/62"
        }}>{`#62`}</a>{` Setup UMD distribution support.`}</li>
    </ul>
    <h4>{`Playground`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/24"
        }}>{`#24`}</a>{` Add playground support for
multiple sandboxes.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Update playground application UX.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/animation`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/70"
        }}>{`#70`}</a>{` Add declarative and extensible
animation component tools.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/36"
        }}>{`#36`}</a>{` Add non-parallel animation
management support.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create animation management documentation.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/sounds`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/81"
        }}>{`#81`}</a>{` Add bleeps provider and bleeps
component set up tools.`}</li>
    </ul>
    <a name='2021' href='#2021'><h2>2021</h2></a>
    <a name='2021-Q1' href='#2021-Q1'><h3>2021-Q1</h3></a>
    <h4>{`General`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/64"
        }}>{`#64`}</a>{` Arwes UI/UX design specification. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h4>{`Website`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/65"
        }}>{`#65`}</a>{` Website application setup.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/67"
        }}>{`#67`}</a>{` Website docs pages. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/design`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Research current React styling tools state to define which ones to use for
the Arwes theming/styling tools.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/83"
        }}>{`#83`}</a>{` Define and create design
style and theme tools.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create design management documentation.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/animation`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Simplify animator TypeScript typing names.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/95"
        }}>{`#95`}</a>{` Refactor Animator animate
hooks to event functions.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/sounds`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Create sounds management documentation.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/99"
        }}>{`#99`}</a>{` Bleeps players shared across
components.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/101"
        }}>{`#101`}</a>{` Multiple component loop
bleep sounds support.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/core`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/93"
        }}>{`#93`}</a>{` Define strategy to extend
core components styles. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/96"
        }}>{`#96`}</a>{` Add application sounds starter
package. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/102"
        }}>{`#102`}</a>{` Component loop bleeps are
not stopped when window is unfocused.`}</li>
    </ul>
    <h5>{`Providers`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/84"
        }}>{`#84`}</a>{` Add Arwes Theme Provider component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h5>{`Global`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/86"
        }}>{`#86`}</a>{` Add StylesBaseline component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h5>{`Content`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/85"
        }}>{`#85`}</a>{` Add Text component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/104"
        }}>{`#104`}</a>{` Add List component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/103"
        }}>{`#103`}</a>{` Add Blockquote component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/88"
        }}>{`#88`}</a>{` Add Table component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/89"
        }}>{`#89`}</a>{` Add CodeBlock component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/87"
        }}>{`#87`}</a>{` Add Figure component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/91"
        }}>{`#91`}</a>{` Add Card component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h5>{`Controls`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/90"
        }}>{`#90`}</a>{` Add LoadingBars component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/100"
        }}>{`#100`}</a>{` Add Button component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h5>{`Structures`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/105"
        }}>{`#105`}</a>{` Add FrameUnderline component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <a name='2021-Q2' href='#2021-Q2'><h3>2021-Q2 [CURRENT]</h3></a>
    <h4>{`General`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/64"
        }}>{`#64`}</a>{` Arwes UI/UX design specification. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Project guidelines related to visual design.`}</li>
    </ul>
    <h4>{`Website`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/67"
        }}>{`#67`}</a>{` Website docs pages. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h4>{`Demos`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Create blog application demo.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Create portfolio application demo.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Create dashboard application demo.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/animation`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/106"
        }}>{`#106`}</a>{` Research and create animator
animated HTML element primitives.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/94"
        }}>{`#94`}</a>{` Animator unmount children
on flow exited.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/63"
        }}>{`#63`}</a>{` Research SEO support for
animated components.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Research and define ways to handle transitions out/in of systems/sub-systems.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Create external animator manager API for dynamically transitioned components.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/sounds`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/97"
        }}>{`#97`}</a>{` Custom bleeps preloading.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/core`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/96"
        }}>{`#96`}</a>{` Add application sounds starter
package. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Define and implement custom application resources loading with integration
with bleeps API `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/97"
        }}>{`#97`}</a>{` and viewport support.`}</li>
    </ul>
    <h5>{`Providers`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/84"
        }}>{`#84`}</a>{` Add Arwes Theme Provider component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h5>{`Global`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/86"
        }}>{`#86`}</a>{` Add StylesBaseline component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h5>{`Content`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/85"
        }}>{`#85`}</a>{` Add Text component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/104"
        }}>{`#104`}</a>{` Add List component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/103"
        }}>{`#103`}</a>{` Add Blockquote component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/88"
        }}>{`#88`}</a>{` Add Table component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/89"
        }}>{`#89`}</a>{` Add CodeBlock component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/87"
        }}>{`#87`}</a>{` Add Figure component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/91"
        }}>{`#91`}</a>{` Add Card component. `}<em parentName="li">{`[Ongoing]`}</em></li>
    </ul>
    <h5>{`Controls`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/90"
        }}>{`#90`}</a>{` Add LoadingBars component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/100"
        }}>{`#100`}</a>{` Add Button component. `}<em parentName="li">{`[Ongoing]`}</em></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Input/Textarea component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Select component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add InputCheckbox component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Nav component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Menu component.`}</li>
    </ul>
    <h5>{`Structures`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/111"
        }}>{`#111`}</a>{` Add FrameBox component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/111"
        }}>{`#111`}</a>{` Add FrameCorners component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/111"
        }}>{`#111`}</a>{` Add FramePentagon component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/111"
        }}>{`#111`}</a>{` Add FrameHexagon component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/111"
        }}>{`#111`}</a>{` Add FrameLines component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/111"
        }}>{`#111`}</a>{` Add FrameUnderline component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add BackgroundGlass component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add BackgroundDots component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add BackgroundBoxes component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add BackgroundHexagons component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Modal component.`}</li>
    </ul>
    <h5>{`Effects`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Appear component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Puffs component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Highlight component.`}</li>
    </ul>
    <a name='2021-Q3' href='#2021-Q3'><h3>2021-Q3</h3></a>
    <h4>{`General`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/18"
        }}>{`#18`}</a>{` Update contributing documentation.`}</li>
    </ul>
    <h4>{`Website`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/66"
        }}>{`#66`}</a>{` Website index page.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/68"
        }}>{`#68`}</a>{` Website API pages.`}</li>
    </ul>
    <h4>{`Playground`}</h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/69"
        }}>{`#69`}</a>{` Playground application UI/UX
design.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/core`}</inlineCode></h4>
    <h5>{`Structures`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Line component.`}</li>
    </ul>
    <h5>{`Controls`}</h5>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add InputRadio component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add FormLegend component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add LoadingCircular component.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Alert component.`}</li>
    </ul>
    <h4><inlineCode parentName="h4">{`@arwes/arwes`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Add Logo component.`}</li>
    </ul>
    <a name='2021-Q4' href='#2021-Q4'><h3>2021-Q4</h3></a>
    <h4><inlineCode parentName="h4">{`@arwes/animation`}</inlineCode></h4>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<a parentName="li" {...{
          "href": "https://github.com/arwes/arwes/issues/98"
        }}>{`#98`}</a>{` Animator performance
optimizations.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      